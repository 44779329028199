import React from 'react'
import Animation from "./Animation"

const ContactUs = () => {
  return (
    <>
      <Animation />
      <div className="page-header contact">
        <div>
          <h1>Our Franchises</h1>
          <p>Venture Of Success </p>
        </div>
      </div>
      <div className="section-div">
        <div class="container ">
          <div className="row">
            <div class="col-md-6 ">

              <form className="form-div">
                <h1>Want to get in touch?</h1>
                <p className="paragraph">We'd love to hear from you. Here's how you can reach us...</p>
                <div class="mb-3 custom-size ">
                  <label for="fullName" class="form-label">Full Name</label>
                  <input type="text" class="form-control" id="fullName" />
                </div>
                <div class="mb-3 custom-size">
                  <label for="email" class="form-label">Email Address</label>
                  <input type="email" class="form-control" id="email" />
                </div>
                <div class="mb-3 custom-size">
                  <label for="phone" class="form-label">Phone Number</label>
                  <input type="tel" class="form-control" id="phone" />
                </div>
                <div class="mb-3 custom-size">
                  <label for="message" class="form-label">Message</label>
                  <textarea class="form-control" id="message" rows="4" ></textarea>
                </div>
                <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
                <button type="submit" class="button button-primary">SUBMIT</button>
              </form>


              <div className="content">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <h1>MAG Ventures Offices</h1>
                      <br />
                      <p><b>Pakistan Head Office:</b> Rauf Tower First Floor, Main Gt Road, Jinnah Blvd west, DHA Phase 2, Islamabad.

                      </p>
                      <p>
                        <b>Sargodha Franchise:</b> Mawk Tech Space Office No. 4 2nd floor, Near Zafar Ullah Chowk Satellite Town, Sargodha.
                      </p>
                      <br />
                      <p>
                        <b>Lahore Franchise:</b>  Second Floor, Building No.84-B/1, Ghalib Market Road, Gulberg-3, Lahore
                      </p>
                      <br />
                      <p>
                        Tel:+92 370 501 8150<br />
                        Email: connect@mag.holdings<br />
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="map-section">

          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207.8580011641545!2d73.12871616540637!3d33.53445637652064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfedda947cee41%3A0x40bb424d279ff636!2sRauf%20Tower!5e0!3m2!1sen!2s!4v1734001911689!5m2!1sen!2s" loading="lazy"></iframe>
        </div>
      </div>
    </>
  )
}

export default ContactUs
