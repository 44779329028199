import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo/magholding.svg';

const Animation = () => {

    const [showOverlay, setShowOverlay] = useState(true);

    const [loading, setLoading] = useState(true);
    useEffect(() => {
      const handleLoad = () => {
        setTimeout(() => {
          setLoading(false);
          window.scrollTo(0, 0);
        }, 3000);
      };
      window.addEventListener('load', handleLoad);
  
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }, []);
  


    // useEffect(() => {
    //     // Hide the overlay after 5 seconds
    //     const timeoutId = setTimeout(() => {
    //         setShowOverlay(false);
    //     }, 30000);

    //     // Clean up the timeout when the component is unmounted
    //     return () => {
    //         clearTimeout(timeoutId);
    //     };
    // }, []);
    return (
        <>
            {loading && (
                <div id="overlay">
                    <div id="loading-items">
                        <a className="logo-loading" title="MAG Ventures">
                            <img src={logo} alt="MAG Ventures Logo" id="logo" />
                        </a>
                    </div>
                </div>
            )}
        </>
    )
}

export default Animation