import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./assets/css/style.css";
import Home from './components/Website/Home';
import Media from './components/Website/Media';
import ContactUs from './components/Website/ContactUs';
import MediaDetail from './components/Website/pages/MediaDetail';
import Main from './components/Website/Main';
import Contact from './components/Website/pages/Contact';
import CommingSoon from './components/Website/pages/CommingSoon';
import Landing from './components/Website/Landing';
import Foundation from './components/Website/pages/Foundation';
import PrivacyPolicy from './components/Website/pages/PrivacyPolicy';
import Services from './components/Website/pages/Services';
import MagVentures from './components/Website/Media/MagVentures';
import MagEleganz from './components/Website/Media/MagEleganz';
import MagMotors from './components/Website/Media/MagMotors';
import MagCafe from './components/Website/Media/MagCafe';
import MagMarket from './components/Website/Media/MagMarket';
import MagBuilders from './components/Website/Media/MagBuilders';
import MagStore from './components/Website/Media/MagStore';
import About from './components/Website/pages/About';

const Index = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Main />} >
            <Route path="" element={<Landing />} />
            <Route path="/" element={<Landing />} />
            <Route path="/commingsoon" element={<CommingSoon />} />
            <Route path="/home" element={<Landing />} />
            <Route path="/media" element={<Media />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/foundation" element={<Foundation />} />
            <Route path="/en/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/en/terms-of-service/" element={<Services />} />
            <Route path="/media/mag-ventures" element={<MagVentures />} />
            <Route path="/media/mag-store" element={<MagStore />} />
            <Route path="/media/mag-builders-and-developers" element={<MagBuilders />} />
            <Route path="/media/mag-super-market" element={<MagMarket />} />
            <Route path="/media/mag-cafe" element={<MagCafe />} />
            <Route path="/media/mag-motors" element={<MagMotors />} />
            <Route path="/media/eleganz-by-mag" element={<MagEleganz />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);