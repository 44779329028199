import React from 'react'
import logo from '../../../assets/images/logo/magholding.svg';
import Animation from '../Animation';
const About = () => {
    return (
        <>
            <Animation />
            <div className="page-header contact">
                <div>
                    <h1>ABOUT US</h1>
                    <p>Venture Of Success </p>
                </div>
            </div>

            <section
                className="simple-section"
                id="about"
                style={{ backgroundColor: "#f8f8f8" }}
            >
                <div className="container">
                    <div className="row align-items-center gy-4">
                        <div className="col-md-12">
                            <div
                                className="section-title mb-4"
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-easing="ease-in-out"
                            >
                                ABOUT US
                            </div>
                            <p
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-easing="ease-in-out"
                            >
                                Welcome to MAG VENTURES, Pakistan's leading venture of success. <br />
                                From investing in thriving markets to introducing wonders and opportunities, Mag
                                Ventures weaves a vibrant tapestry of experiences across Pakistan and beyond. Our
                                portfolio includes markets that touch your everyday life such as construction,
                                supermarkets, food chains, fashion and beauty products, and exclusive partnerships
                                with Apple products. Our commitment to excellence drives our expansion into the
                                vibrant markets of the Asia Region through our various projects like Mag Store, Mag
                                Builders & Developers, Mag Supermarket, Mag’s Cafe, Mag Motors, which focuses on
                                the automobile industry including the import and export of cars and auto parts and
                                operating a car showroom. And Eleganz by Mag which brings the worlds of beauty and
                                fashion together.
                                We are also thrilled to introduce our newest venture- MAG Organic Meats which not
                                only caters to the local market but also engages in the export and trade of high-quality,
                                organic meat, ensuring the best standards from farm to table. As an umbrella entity,
                                MAG VENTURES fosters growth, innovation, and quality service in every facet of our
                                operations. We're not just a company; we're a network of opportunities. Through our
                                affiliate programs, we invite you to join this exciting journey, to build a story together,
                                brick by brick, bite by bite, and innovation by innovation.

                            </p>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}

export default About